import React from 'react';
import './About.css';
import { Link } from 'react-router-dom';
import AboutUsSectionImg from '../../utils/images/ntti.jpg';
import ChooseSection from '../../components/ChooseSection/ChooseSection';
import Person1 from '../../utils/images/blog_a.jpg';
import Person2 from '../../utils/images/blog_b.jpg';
import Person3 from '../../utils/images/blog_c.jpg';
import Person4 from '../../utils/images/blog_e.jpg';
import Person5 from '../../utils/images/blog_d.jpg';
import Person6 from '../../utils/images/blog_f.jpg';
import Person7 from '../../utils/images/blog_g.jpg';
import Person8 from '../../utils/images/blog_g.jpg';
import Person9 from '../../utils/images/blog_h.jpg';

const persons = [
    {
        id: 1,
        img: [Person1]
    },
    {
        id: 2,
        img: [Person2]
    },
    {
        id: 3,
        img: [Person3]
    },
    {
        id: 4,
        img: [Person4]
    },
    {
        id: 5,
        img: [Person5]
    },
    {
        id: 6,
        img: [Person6]
    },
    {
        id: 7,
        img: [Person7]
    },
    {
        id: 8,
        img: [Person8]
    },
    {
        id: 9,
        img: [Person9]
    },
];

function About() {
  return (
    <div className='about-page'>
        <header className='height-75'>
            <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
                <h1 className='text-center fw-semibold'>About Us</h1>
                <p className='text-center w-75 mb-5'>Welcome to AIC Naivasha Technical Training Institute! We are dedicated to providing exceptional technical education and training to help you achieve your career goals. Our institute combines expert faculty, state-of-the-art facilities, and a supportive learning environment to foster your growth and success.
                  Learn more about our mission, values, and the team that makes our institute a leading choice for technical education</p>
            </div>
        </header>

        <div className='container my-5'>
            <div className="row">
                <div className='col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'>
                    <h2 className='mb-4 mb-lg-5'>Study with us</h2>
                    <p>Our institute boasts a team of highly qualified instructors who bring real-world experience and expertise into the classroom. 
                      They are dedicated to your success, offering personalized guidance and support throughout your educational journey. Additionally, 
                      our state-of-the-art facilities, including modern classrooms,
                       well-equipped labs, and specialized workshops, ensure that you receive hands-on training that mirrors industry standards.</p>
                    <p className='mb-4 mb-lg-5'>We believe in a holistic approach to education that integrates academic rigor with practical application.
                      Our curriculum is designed to be relevant and up-to-date, incorporating the latest industry trends and technologies. Beyond the classroom,
                       students have access to a range of resources, including career services,
                       internships, and industry partnerships, all aimed at enhancing your learning experience and preparing you for the real world.</p>
                    <Link to="/contact">
                        <button type='button' className='btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Contact Us</button>
                    </Link>
                </div>
                <div className='col-lg-6 d-flex justify-content-center'>
                    <img src={AboutUsSectionImg} className='img-fluid w-80' alt="" />
                </div>
            </div>
        </div>

        <div className='bg-dark text-light py-5'>
            <ChooseSection />
        </div>

        <div className='bg-body-tertiary py-5'>
            <div className="container">
                <h2 className='text-center mb-5'>Our Success Journey</h2>
                <div className='row g-4'>
                    {persons.map((person) => (
                        <div key={person.id} className='col-md-4'>
                            <img src={person.img} className='img-fluid' alt="" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
  )
}

export default About;