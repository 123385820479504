import React from 'react';
import './Courses.css';
import { Card } from 'react-bootstrap';
import ArtCourseImg from '../../utils/images/hosiptality.jpg';
import BusinessCourseImg from '../../utils/images/accountant.png';
import ComputerScienceCourseImg from '../../utils/images/ict.jpg';
import EducationCourseImg from '../../utils/images/cosmetology.png';
import HealthcareCourseImg from '../../utils/images/wood_work.jpg';
import LawCourseImg from '../../utils/images/electrical.png';
import DressCourseImg from '../../utils/images/dressmarking.jpg';
import WeldCourseImg from '../../utils/images/welding.jpg';
import MotorCourseImg from '../../utils/images/auto-motive.jpg';
import PlumbCourseImg from '../../utils/images/plumb.jpg';
import FaqAccordion from '../../components/FaqAccordion/FaqAccordion';

const courses = [
    {
        id: 1,
        img: [ArtCourseImg],
        title: 'Food & Beverage',
        description: 'Delve into the culinary arts with our Food & Beverage program. Learn the fundamentals of cooking, food safety, and hospitality management, preparing you for a career in the culinary industry.'
    },
    {
        id: 2,
        img: [BusinessCourseImg],
        title: 'Business Administation',
        description: 'Develop a strong foundation in business principles with our Business program. Learn essential skills in management, finance, marketing, and entrepreneurship, empowering you to navigate the complexities of the modern business world and drive success in any organization.'
    },
    {
        id: 3,
        img: [ComputerScienceCourseImg],
        title: 'ICT Course',
        description: 'Dive into the world of Information and Communication Technology with our ICT program. From programming and network administration to cybersecurity, this course equips you with the technical expertise needed to excel in the ever-evolving tech industry.'
    },
    {
        id: 4,
        img: [EducationCourseImg],
        title: 'Cosmetology',
        description: 'Unlock your creativity with our Cosmetology course, where you’ll master the art of beauty and wellness. From hairdressing and skincare to makeup artistry, this program offers hands-on training to help you become a skilled professional in the beauty industry.'
    },
    {
        id: 5,
        img: [HealthcareCourseImg],
        title: 'Carpentry & Joinery',
        description: 'Our Carpentry and Joinery program teaches you the craftsmanship of working with wood. Learn to design, build, and repair wooden structures and furniture with precision, combining traditional techniques with modern tools for a rewarding career in construction and craftsmanship.'
    },
    {
        id: 6,
        img: [LawCourseImg],
        title: 'Electrical & Electronic',
        description: 'The Electrical program provides you with the knowledge and skills to work in the electrical field. Covering everything from wiring and circuit design to electrical systems maintenance, this course prepares you for a career as a certified electrician or electrical technician.',
    },{
        id: 7,
        img: [WeldCourseImg],
        title: 'Welding & Fabrication',
        description: 'Learn the essential techniques of welding and metal fabrication. Our course covers everything from basic welding to advanced fabrication, preparing you for a career in construction and manufacturing.',
    },
    {
        id: 8,
        img: [DressCourseImg],
        title: 'Fashion Design',
        description: 'Express your creativity through fashion. Our Fashion Design course teaches you the art of creating stylish and functional clothing, from concept to finished product.',
    },
    {
        id: 9,
        img: [MotorCourseImg],
        title: 'Motor Vehicle & Mechanics',
        description: 'Dive into the world of automotive technology with our Motor Vehicle & Mechanics course. Gain practical skills in vehicle maintenance, repair, and diagnostics, and drive your career forward.',
    },{
        id: 10,
        img: [PlumbCourseImg],
        title: 'Plumbing',
        description: 'Become a skilled plumber with our comprehensive plumbing course. Learn about water systems, pipe fitting, and installation techniques, and start a career in this essential trade.',
    }
];

function Courses() {
  return (
    <div className='courses-page'>
        <header className='height-75'>
            <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
                <h1 className='text-center fw-semibold'>Our Courses</h1>
                <p className='text-heading text-center w-75 mb-5'>Explore a diverse range of programs at AIC Naivasha Technical Training Institute, designed to equip you with the skills and knowledge needed for today’s competitive job market. Whether you’re pursuing a career in engineering, IT, business, or hospitality, our hands-on courses are tailored to meet industry demands and help you achieve your professional goals.</p>
            </div>
        </header>

        <div className='container py-5'>
            <div className='row g-4'>
                {courses.map((course) => (
                    <div key={course.id} className='col-lg-6'>
                        <Card className='text-white shadow scale-hover-effect courseCard'>
                            <Card.Img src={course.img} />
                            <Card.ImgOverlay className='d-flex flex-column align-items-center justify-content-center p-md-5'>
                                <Card.Title className='fs-1 text-default'>{course.title}</Card.Title>
                                <Card.Text className='description text-center'>{course.description}</Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </div>
                ))}
            </div>
        </div>

        <div className='bg-dark text-light py-5'>
            <FaqAccordion />
        </div>
    </div>
  )
}

export default Courses;