import React from 'react';
import './Blog.css';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Blog1Img from '../../utils/images/blog_d.jpg';
import Blog2Img from '../../utils/images/blog_f.jpg';
import Blog3Img from '../../utils/images/blog_n.jpg';
import Blog4Img from '../../utils/images/blog_a.jpg';
import Blog5Img from '../../utils/images/blog_b.jpg';
import Blog6Img from '../../utils/images/blog_c.jpg';
import Blog7Img from '../../utils/images/blog_e.jpg';
import Blog8Img from '../../utils/images/blog_h.jpg';
import Blog9Img from '../../utils/images/blog_k.jpg';

const blogs = [
    {
        id: 1,
        img: [Blog1Img],
        title: 'Celebrating Excellence',
        description: 'Every year, the Best Student Award recognizes a remarkable individual whose hard work, dedication, and passion for learning set them apart from their peers. This award is more than just an accolade; it’s a testament to the endless possibilities that come from perseverance and a commitment to excellence'
    },
    {
        id: 2,
        img: [Blog2Img],
        title: 'Capturing Triumph',
        description: 'Graduation is a milestone that marks the culmination of years of hard work and dedication. To make the most of this special occasion, preparation is key. From selecting the perfect attire and mastering the ceremonial walk to managing emotions and making memorable connections'
    },
    {
        id: 3,
        img: [Blog3Img],
        title: 'Our Dedicated Team',
        description: 'At NTTI College, our commitment to excellence extends beyond our students to the dedicated professionals who drive our institution forward. Competitive staff members are the backbone of our success, bringing innovation, passion, and a relentless pursuit of quality to everything they do.'
    },
    {
        id: 4,
        img: [Blog4Img],
        title: 'Empowering Alumni',
        description: 'Unlock the full potential of your alumni network! Discover how staying connected with fellow graduates can open doors to career opportunities, professional development, and valuable mentorship.'
    },
    {
        id: 5,
        img: [Blog5Img],
        title: 'Shaping Leaders',
        description: 'At NTTI, mentorship is not just about guiding individuals through their professional journeys; its about fostering the next generation of leaders. Effective mentorship instills confidence, hones leadership skills, and provides invaluable insights into navigating the complexities of leadership roles.'
    },
    {
        id: 6,
        img: [Blog6Img],
        title: 'Power of Teamwork',
        description: 'Teamwork is at the heart of success in any collaborative environment, and at NTTI, we believe in harnessing the collective strengths of our students to drive innovation and excellence. Effective teamwork fosters diverse perspectives, enhances problem-solving abilities, and builds a supportive network.'
    },
    {
        id: 7,
        img: [Blog7Img],
        title: 'Achievements',
        description: 'At NTTI College, we take immense pride in the remarkable achievements and milestones reached by our top-performing students. From academic excellence to outstanding extracurricular accomplishments, these students have demonstrated exceptional dedication and talent.'
    },
    {
        id: 8,
        img: [Blog8Img],
        title: 'Shaping the Future',
        description: 'Education is the cornerstone of societal transformation, fostering growth, innovation, and empowerment. At NTTI College, we are committed to nurturing minds and equipping students with the skills and knowledge necessary to drive positive change.'
    },
    {
        id: 9,
        img: [Blog9Img],
        title: 'Unlocking Potential',
        description: 'At NTTI College, we believe that education goes beyond the classroom. Extracurricular activities play a crucial role in shaping well-rounded individuals, offering students opportunities to develop leadership skills, build strong networks, and explore their passions.'
    },
];

function Blog() {
  return (
    <div className='blog-page'>
        <header className='height-75'>
            <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
                <h1 className='text-center fw-semibold'>Our Blog</h1>
                <p className='text-center w-75 mb-5'>
At NTTI College, our strength lies in the people who bring our mission to life every day. In this special feature, we present a visual journey that highlights the faces behind our success—the dedicated team of educators, administrators, and support staff who work tirelessly to create an inspiring learning environment. These photos capture the spirit of collaboration, commitment, and excellence that defines NTTI College. Join us as we celebrate the individuals who make our institution a place where innovation and education flourish.</p>
            </div>
        </header>

        <div className='bg-body-tertiary py-5'>
            <div className="container">
                <div className="row g-4">
                    {blogs.map((blog) => (
                        <div key={blog.id} className='col-md-6 col-lg-4'>
                            <Link to="/blog" className='text-decoration-none'>
                                <Card className='h-100 shadow scale-hover-effect bg-dark text-light border-0'>
                                    <Card.Img variant="top" src={blog.img} alt='loading...' />
                                    <Card.Body className='p-md-5 d-flex flex-column align-items-center'>
                                        <Card.Title className='fs-4 strong mb-4'>{blog.title}</Card.Title>
                                        <Card.Text className='description text-center'>{blog.description}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Blog;