import React from 'react';
import './Contact.css';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import pinlocation from '../../utils/images/pin_location.png';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

function Contact() {
    const institutePosition = [-0.7267429796697058, 36.44822263904641];

    const pinIcon = new L.Icon({
        iconUrl: pinlocation, // Replace with the URL to your custom pin icon
        iconSize: [32, ], // Size of the icon
        iconAnchor: [16, 32], // Anchor point of the icon
        popupAnchor: [0, -32] // Popup anchor point
      });

      if (!Array.isArray(institutePosition) || institutePosition.length !== 2 ||
      isNaN(institutePosition[0]) || isNaN(institutePosition[1])) {
    console.error('Invalid coordinates:', institutePosition);
    return null;
  }
  return (
    <div className='contact-page'>
        <header className='height-75'>
            <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
                <h1 className='text-center fw-semibold'>Get In Touch</h1>
                <p className='text-center w-75 mb-5'>Have questions or need more information? We’re here to help! Whether you’re interested in our programs, admissions process, or just want to learn more about AIC Naivasha Technical Training Institute, 
                  our team is ready to assist you. Reach out to us through any of the channels below, and we’ll get back to you promptly.</p>
            </div>
        </header>

        <div className='container my-5 d-flex justify-content-center'>
            <Form id='contact-form'>
                <Row className='mb-3'>
                    <Col sm={12} md={6} className='mb-3 mb-md-0'>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control placeholder='First name' />
                    </Col>
                    <Col sm={12} md={6}>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control placeholder='Last name' />
                    </Col>
                </Row>

                <Form.Group className='mb-3'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type='email' placeholder='Enter email' />
                    <Form.Text className='text-muted'>
                        We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>

                <Form.Group className='mb-3'>
                    <Form.Label>Address</Form.Label>
                    <Form.Control placeholder='Your address' />
                </Form.Group>
                <Form.Group className='mb-3'>
                    <Form.Label>Your Message</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                </Form.Group>

                <Button variant="danger btn-lg" type='submit'>Submit</Button>
            </Form>
        </div>

        <div className='bg-dark text-light p-5'>
        <div className='container'>
      <h2 className='text-center mb-5'>Our Location</h2>
      <div style={{ height: '500px', width: '100%' }}>
        <MapContainer center={institutePosition} zoom={14} style={{ height: '100%', width: '100%' }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={institutePosition} icon={pinIcon}>
            <Popup>AIC Naivasha Technical Training Institute</Popup>
          </Marker>
        </MapContainer>
      </div>
     </div>
     </div>
   </div>
  )
}

export default Contact;