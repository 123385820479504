import React from 'react';
import './FaqAccordion.css';
import Accordion from 'react-bootstrap/Accordion';

function FaqAccordion() {
  return (
    <div className='faq-section'>
        <div className='container d-flex flex-column align-items-center'>
            <h2 className='text-center text-capitalize mb-5'>Frequently asked questions (FAQs)</h2>
            <p className='text-center mb-5'>Have questions? We’ve got answers! Below, you'll find responses to some of the most common inquiries about AIC Naivasha Technical Training Institute. Whether you’re curious about our programs, application process, or campus life, this section is here to help you get the information you need quickly and easily</p>
            <Accordion defaultActiveKey="" flush>
                <Accordion.Item eventKey='0'>
                    <Accordion.Header>What programs does AIC Naivasha Technical Training Institute offer ?</Accordion.Header>
                    <Accordion.Body>
                    We offer a variety of technical and vocational programs including Engineering, Information Technology, Business Studies, and Hospitality and Tourism. Each program is designed to equip you with the skills needed to succeed in your chosen field.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1'>
                    <Accordion.Header>When is the next course intake ?</Accordion.Header>
                    <Accordion.Body>
                    For Technical courses, Secretarial, Business, and ICT programs, intakes are held three times a year: in January, May, and September. However, if you're interested in Computer Studies, you can apply at any time, as intakes for this program are conducted throughout the year.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2'>
                    <Accordion.Header>Is there accommodation available on campus ?</Accordion.Header>
                    <Accordion.Body>
                    Yes, we offer on-campus accommodation for students. Our hostels are safe, affordable, and conveniently located near academic facilities. Spaces are limited, so early application is recommended.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='3'>
                    <Accordion.Header>What facilities are available to students ?</Accordion.Header>
                    <Accordion.Body>
                    Students at AIC Naivasha Technical Training Institute have access to modern workshops, computer labs, a well-equipped library, and recreational facilities. We also have a cafeteria, health services, and a vibrant student life with clubs and organizations.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='3'>
                    <Accordion.Header>How do I contact the institute for more information ?</Accordion.Header>
                    <Accordion.Body>
                    You can reach us via phone at 0797-720-740 / 0797-561-109 / 020-2026362, email at aicpolytechnic@gmail.com, or visit our Contact Us Page for more options. Our admissions team is always ready to assist you with any inquiries.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    </div>
  )
}

export default FaqAccordion;