import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import ChooseSection from '../../components/ChooseSection/ChooseSection';
import StartCoursesImg from '../../utils/images/comp_lab.jpg';
import FaqAccordion from '../../components/FaqAccordion/FaqAccordion';
import { Card } from 'react-bootstrap';
import Blog1Img from '../../utils/images/director.png';
import Blog2Img from '../../utils/images/manager.png';
import Blog3Img from '../../utils/images/accountant.png';

const blogs = [
    {
        id: 1,
        img: [Blog1Img],
        title: 'Director ',
        name:'Rev Simon Kinyanjui',
        description: 'Welcome to AIC Naivasha Technical Training Institute! Our mission is to provide high-quality technical education that empowers our students to succeed in their chosen careers. We are committed to fostering a nurturing and innovative learning environment where every student can thrive. Join us and be part of a community dedicated to excellence and lifelong learning.'
    },
    {
        id: 2,
        img: [Blog2Img],
        title: 'Manager',
        name: 'Mr George Juma Otieno',
        description: 'At AIC Naivasha Technical Training Institute, we pride ourselves on offering a dynamic and supportive environment for our students. Our programs are designed to meet industry standards and prepare you for the real world. We believe in the potential of every student and are here to support your journey to success. Let’s build a brighter future together.'
    },
    {
        id: 3,
        img: [Blog3Img],
        title: 'Accountant',
        name: 'Mr Peter Gachoka',
        description: 'The Accounts Department at AIC Naivasha Technical Training Institute is dedicated to ensuring a seamless financial experience for all our students. We provide transparent information on tuition fees, payment plans, and scholarships to help you manage your education expenses effectively. If you have any questions or need assistance, our team is here to support you every step of the way.'
    }
];

function Home() {
  return (
    <div className='home-page'>
        <header className='h-100 min-vh-100 d-flex align-items-center text-light'>
            <div className='container d-flex flex-column align-items-center'>
                <h2>Welcome To</h2>
                <h1 className='text-center fw-semibold'>AIC Naivasha Technical Training Institute</h1>
                <p>At AIC Naivasha Technical Training Institute, we believe in the transformative power of technical education. Nestled in the serene environment of Naivasha, our institute is a beacon of learning and innovation, dedicated to equipping students with the skills and knowledge they need to thrive in today’s dynamic job market.</p>
                <div className='d-flex flex-column flex-sm-row align-items-center about-button'>
                    <Link to="/courses">
                        <button type='button' className='btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Our Courses</button>
                    </Link>
                    <Link to="/contact">
                        <button type='button' className='btn btn-outline-light btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Contact Us</button>
                    </Link>
                </div>
            </div>
        </header>

        <div className="py-5">
            <ChooseSection />
        </div>

        <div className='py-5 bg-light'>
            <div className="container">
                <div className='row d-flex align-items-center justify-content-around'>
                    <div className='col-lg-5'>
                        <h2 className='text-capitalize'>Course Intake Now Open!</h2>
                        <p>Take the first step toward your future at AIC Naivasha Technical Training Institute. Our course intake is now open,
                           offering a wide range of programs tailored to equip you with practical skills and industry-relevant knowledge.
                           Whether you're a recent school graduate or looking to advance your career, we have the right program for you</p>
                        <Link to="/courses">
                            <button type='button' className='btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Learn More</button>
                        </Link>
                    </div>
                    <div className='col-lg-5 mt-5 mt-lg-0'>
                        <img src={StartCoursesImg} className='img-fluid' alt="" />
                    </div>
                </div>
            </div>
        </div>

        <div className="py-5">
            <FaqAccordion />
        </div>

        <div className='blog-section text-light py-5'>
            <div className='container d-flex flex-column align-items-center'>
                <h2 className='text-center text-capitalize mb-5'>Latest on the blog</h2>
                <div className='row g-4'>
                    {blogs.map((blog) => (
                        <div key={blog.id} className='col-md-6 col-lg-4'>
                            <Link to="/blog" className='text-decoration-none'>
                                <Card className='h-100 shadow scale-hover-effect'>
                                    <Card.Img variant="top" src={blog.img} />
                                    <Card.Body className='p-md-5'>
                                        <Card.Title>
                                          <h4 className='username'>{blog.name}</h4>
                                          <span className='usertitle'>{blog.title}</span></Card.Title>
                                        <Card.Text className='usermessage'>{blog.description}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </div>
                    ))}
                </div>
                <Link to="/blog">
                    <button type='button' className='btn btn-danger btn-lg mt-5'>Read More Blogs</button>
                </Link>
            </div>
        </div>
    </div>
  )
}

export default Home;